import React from "react";
import type { GetServerSideProps, NextPage } from "next";
import Head from "next/head";

import { IProjectList } from "shared/components-pages/proyectos/ProjectList";
import en from "lang/en.json";
import es from "lang/es.json";
import { CPagesPath } from "shared/models/const/pages-path";
import RealestateList from "shared/components-pages/proyectos/RealestateList";
import { useTranslations } from "next-intl";
import createCanonicalUrl from "@helpers/removeparams";

export const getServerSideProps: GetServerSideProps = async (context) => {
  const locale = context.locale;

  return {
    props: {
      messages: locale === "es" ? es : en,
      url: context.resolvedUrl,
    },
  };
};

const Propiedad: NextPage = (props: any) => {
  const titleT = useTranslations("title-pages");

  const filters: IProjectList = {
    refRequest: "propiedades-list-query",
    detailPath: CPagesPath.es.property_detail,
    filters: {
      typeProperty: [],
    },
  };

  return (
    <>
      <Head>
        <title>{titleT("properties")}</title>

        <meta name="description" content="" />
        <meta
          name="keywords"
          content="Propiedad exclusivos, proyecto exclusivo en construcción, proyecto exclusivo construido, Propiedad exclusivos remax rd, casas en venta, villas en venta, townhouse en venta, apartamentos en venta, oficinas en venta, almacén en venta, casas en alquiler, villas en alquiler, townhouse en alquiler, apartamento en alquiler, oficinas en alquiler, almacén en alquiler, Propiedad exclusivos de remax república dominicana"
        />
        <link
          rel="canonical"
          href={createCanonicalUrl(
            `${process.env.NEXT_PUBLIC_DOMAIN_URL}${props.url}`
          )}
          key="canonical"
        />
      </Head>
      <RealestateList defaultFilters={filters} />
    </>
  );
};

export default Propiedad;
